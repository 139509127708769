:root {
  --darkBlue: #093763;
  --lightblue: #72aad9;
  --darkGreen: #092327;
  --red: #ff5733;
  --regular: #286ca4;
  --Title-font: "Cinzel", serif;
  --para-font: "Nunito", sans-serif;
}
.footer {
  position: relative;
  padding: 10px 20px;
  background-image: url("../../images/homeBanner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  background-color: var(--darkGreen);
  height: 120vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80vh;
}
.footer-overlay {
  position: relative;
}

.sec-foo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: lightgray;
  opacity: 0.7;
  /* Black color with 50% opacity */
}
.footer img {
  width: fit-content;
  position: relative;
  margin-top: 100px;
}
.facebook-icon-footer,
.tiktok-icon-footer,
.linkedin-icon-footer,
.youtube-icon-footer {
  font-size: 25px;
  margin: 0px 15px;
  position: relative;
  color: red;
}
.foo-text {
  position: relative;
}
.facebook-icon-footer:hover,
.tiktok-icon-footer:hover,
.linkedin-icon-footer:hover,
.youtube-icon-footer:hover {
  opacity: 0.3;
  position: relative;
  cursor: pointer;
}