@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
:root {
  --darkBlue: #093763;
  --lightBlue: #72aad9;
  --regular: #286ca4;
  --Title-font: "Cinzel", serif;
  --para-font: "Nunito", sans-serif;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0px !important;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--darkBlue);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--regular);
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 8px;
}
