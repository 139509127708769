:root {
  --darkBlue: #093763;
  --lightblue: #f1c40f;
  --darkGreen: #092327;
  --red: #ff5733;
  --regular: #286ca4;
  --Title-font: "Cinzel", serif;
  --para-font: "Nunito", sans-serif;
}

/* ############################## HERO SECTION DESIGN #############################  */

.bg-image {
  background: url("../../images/homeBanner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.hero-left-container .left-inner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  padding: 0px 50px;
}
.hero-main-heading {
  margin-top: 10px;
  font-size: 65px;
  font-weight: bold;
  font-family: var(--Title-font);
  color: white;
}
.hero-sub-heading {
  margin-top: 10px;
  font-size: 65px;
  font-weight: bold;
  font-family: var(--para-font);
  color: white;
}
.para-1 {
  font-size: 19px;
  font-weight: 300;
  font-family: var(--Title-font);
  color: white;

  text-shadow: 0px 0px 10px gray;
}
.para-2 {
  margin-top: 0px;
  font-size: 25px;
  font-weight: 800;
  font-family: var(--para-font);
  color: white;
}

.hero-right-container img {
  margin-top: 20px !important;
}
.bg-overlay {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: relative;
  background-attachment: fixed;
}

.hero-carousel {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.slide-of-hero {
  position: relative;
  height: 100vh;
}

.slide-of-hero video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: lightgray;
  opacity: 0.7;
  height: 100%;
  z-index: 1;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  color: black;
  text-align: left;
  z-index: 2;
  animation: fadeInUp 1.5s ease-in-out;
  font-weight: bold;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}

.text-overlay h3 {
  font-weight: bold;
  font-size: 1.8rem;
  font-family: var(--Title-font);
}
.text-overlay h3 span {
  font-size: 1.8rem;
  font-family: var(--Title-font);
  color: red;
}

.text-overlay h1 {
  font-size: 3rem;
  font-family: var(--Title-font);
  color: red;
}

.text-overlay h1 span {
  font-family: var(--Title-font);
  color: black;
  font-weight: bold;
  font-size: 1.8rem;
  border-bottom: 2px solid red;
}

.text-overlay p {
  font-size: 1.8rem;
  max-width: 1000px;
  margin: 0;
  word-spacing: 2px;
  font-family: var(--para-font);
}
.text-overlay p span {
  font-size: 2.8rem;
  font-family: var(--Title-font);
  color: #f1c40f;
}

.typeWritter {
  margin: 30px 0px;
  color: red;
  font-size: 2rem;
  font-weight: bold;
}

/* Additional styles for slick dots */
.slick-dots li button:before {
  color: white;
  font-size: 12px;
  opacity: 0.75;
}

.slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .text-overlay h3 {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  .text-overlay h1 {
    margin-bottom: 30px;
    font-size: 2.5rem;
  }
  .text-overlay p {
    font-size: 1rem;
    max-width: 500px;
    word-spacing: 0px;
  }
  .text-overlay p span {
    font-size: 1rem;
    font-family: var(--Title-font);
  }
  .typeWritter {
    margin: 20px 0px;
    font-size: 1rem;
    font-weight: bold;
  }
}

@media (max-width: 480px) {
  .text-overlay h3 {
    font-size: 1rem;
  }
  .text-overlay h1 {
    font-size: 2rem;
  }
  .text-overlay p {
    font-size: 0.875rem;
  }
  .text-overlay p span {
    font-size: 0.875rem;
    font-family: var(--Title-font);
    color: red;
  }
  .typeWritter {
    margin: 10px 0px;
    color: red;
    font-size: 0.875rem;
    font-weight: bold;
  }
}

/* ############################## BOOST YOUR BUSINESS SECTION DESIGN #############################  */
.section-2 {
  background-image: url("../../images/bussinessGrowth.jpg");
  position: relative;
  padding: 10px 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
}
.section-2 .main-heading {
  color: black;
  font-family: var(--Title-font);
  font-size: 45px;
  font-weight: light;
  position: relative;
  z-index: 2;
}
.overlay-2 {
  position: relative;
}

.sec-2-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: lightgray;
  opacity: 0.7;
}
.section-2 .main-heading span {
  font-weight: bold;
  position: relative;
  z-index: 1;
  color: red;
  text-transform: uppercase;
  font-size: 40px;
  font-family: var(--Title-font);
}
.section-2 .main-heading span span {
  border-bottom: 2px solid red;
  font-weight: bold;
  position: relative;
  z-index: 1;
  color: black;
  text-transform: uppercase;
  font-size: 40px;
  font-family: var(--Title-font);
}

.sec-2-para-1,
.sec-2-para-3 {
  color: black;
  font-family: var(--para-font);
  position: relative;
  z-index: 1;
  font-size: 20px;
}
.sec-2-para-2 {
  font-size: 20px;
  font-family: var(--para-font);
  color: red;
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .section-2 .main-heading {
    font-size: 35px;
  }
  .section-2 .main-heading span {
    font-size: 30px;
  }
  .section-2 .main-heading span span {
    font-size: 30px;
  }
}
@media (max-width: 480px) {
  .section-2 .main-heading {
    font-size: 35px;
  }
  .section-2 .main-heading span {
    font-size: 30px;
  }
  .section-2 .main-heading span span {
    font-size: 30px;
  }
}
/* ############################## CONTACT TODAY DESIGN #############################  */

.section-3 {
  background-color: white;
}

.section-3 .sec-3-main-heading {
  font-size: 35px;
  font-family: var(--Title-font);
  color: black;
  font-weight: bold;
}
.section-3 .sec-3-main-heading span {
  font-size: 35px;
  font-family: var(--Title-font);
  color: red;
  font-weight: bold;
}
.section-3 .sec-3-para {
  font-size: 20px;
  font-family: var(--para-font);
  color: red;
  font-weight: bold;
}
.section-3 .sec-3-button {
  font-size: 20px;
  font-weight: lighter;
  background-color: red;
  color: white;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: ease-in-out 0.5s;
}
.section-3 .sec-3-button:hover {
  opacity: 0.7;
}

/* ############################## ABOUT SECTION DESIGN #############################  */

.section-4 {
  background-color: #fff;
}
.sec-4-heading {
  color: black;
  font-size: 40px;
  font-family: var(--Title-font);
  padding: 10px 0px;
  margin: 20px 40px;
  width: auto;
}
.sec-4-heading span {
  color: red;
  font-size: 40px;
  border-bottom: 2px solid black;
  font-family: var(--Title-font);
  width: auto;
}
.sec-4-desc {
  font-size: 22px;
  font-family: var(--para-font);
  padding: 10px 0px;
  margin: 10px 20px 10px 40px;
  color: black;
  font-weight: bold;
}
.sec-4-desc span {
  font-size: 25px;
  font-family: var(--Title-font);
  color: red;
  font-weight: bold;
  border-bottom: 1px solid #2c3e50;
}
.sec-4-list li {
  font-size: 20px;
  font-weight: lighter;
  font-weight: bold;
}

.sec-4-list li:nth-child(odd) {
  font-family: var(--para-font);
  margin: 10px 0px;
  color: red;
  font-weight: bold;
}
.sec-4-list li:nth-child(even) {
  margin: 10px 0px;
  font-family: var(--para-font);
  color: black;
  font-weight: bold;
}

.section-4-card {
  width: 100%;
  max-width: 400px;
  height: 750px;
  margin: auto;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: black;
}

.sec-4-card-img {
  position: relative;
  overflow: hidden;
}

.sec-4-card-img img {
  width: 100%;
  transform: scale(0.9);
  transition: transform 0.3s ease;
  border-radius: 10px;
}

.sec-4-card-img:hover img {
  transform: scale(1.1); /* Zoom out on hover to show the full image */
}

.sec-4-card-content {
  padding: 20px;
}

.sec-4-card-content h1 {
  font-size: 25px;
  margin: 10px 0;
  font-family: var(--para-font);
  font-weight: normal;
  color: white;
}
.sec-4-card-content h1 span {
  font-size: 30px;
  margin: 10px 0;
  font-family: var(--Title-font);
  color: red;
  font-weight: bold;
}

.sec-4-card-content h2 {
  color: red;
  border-bottom: 2px solid white;
  font-weight: bold;
  margin: 5px 0 30px 0px;
  font-family: var(--Title-font);
}
.sec-4-card-content h2 span {
  color: white;
  font-weight: bold;
  margin: 5px 0;
  font-family: var(--Title-font);
}

.sec-4-card-content p {
  color: white;
  font-size: 25px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .section-4-card {
    max-width: 90%;
    height: 700px;
  }
  .sec-4-heading {
    font-size: 30px;
  }
  .sec-4-heading span {
    font-size: 30px;
  }
  .sec-4-desc {
    font-size: 22px;
  }
  .sec-4-desc span {
    font-size: 25px;
  }
  .sec-4-list li {
    font-size: 20px;
  }
}
@media (max-width: 480px) {
  .section-4-card {
    max-width: 90%;
    height: 700px;
  }
  .sec-4-heading {
    font-size: 30px;
  }
  .sec-4-heading span {
    font-size: 30px;
  }
  .sec-4-desc {
    font-size: 22px;
  }
  .sec-4-desc span {
    font-size: 25px;
  }
  .sec-4-list li {
    font-size: 20px;
  }
}
/* ############################## BUSINESS IDEAS DESIGN SECTION DESIGN #############################  */

.section-5 {
  background-image: url("../../images/bussinessIdeas.jpg");
  position: relative;
  padding: 10px 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
.overlay-5 {
  z-index: 0;
  position: relative;
}
.sec-5-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: lightgray;
  opacity: 0.7;
  z-index: 0;
}
.sec-5-main-heading {
  z-index: 1;
  color: black;
  font-size: 40px;
  font-weight: bold;
  font-family: var(--Title-font);
  padding: 30px 0px 0px 0px;
  position: relative;
}

.sec-5-span {
  z-index: 1;
  color: red;
  font-size: 40px;
  font-weight: bold;
  font-family: var(--Title-font);
  position: relative;
  border-bottom: 2px solid black;
}

.sec-5-text {
  z-index: 1;
  color: black;
  font-size: 25px;
  font-weight: bold;
  font-family: var(--para-font);
  margin: 30px 0px !important;
  position: relative;
}

.card {
  width: 100%;
  max-width: 300px;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: lightgray;
}

.card {
  transition: transform 0.3s, box-shadow 0.3s;
  border: none;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.card-img-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 300px;
  object-fit: cover;
}

.card-body {
  padding: 20px;
  text-align: center;
}

.card-title {
  font-weight: bold;
  font-size: 20px;
  font-family: var(--Title-font);
  color: red;
  margin-bottom: 15px;
}

.card-text {
  font-weight: bold;
  font-size: 15px;
  font-family: var(--para-font);
  color: black;
}

.card-1,
.card-2,
.card-3,
.card-4,
.card-6 {
  width: 100%;
  height: 200px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 5px 20px white;
  padding: 30px 50px;
  transition: 0.3s;
  background-color: white;
  margin: 80px 0px;
  cursor: pointer;
  z-index: 0;
}
.card-1:hover,
.card-2:hover,
.card-3:hover,
.card-4:hover,
hover,
.card-6:hover {
  height: 370px;
}
.card-1-img,
.card-2-img,
.card-3-img,
.card-4-img,
img,
.card-6-img {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateY(-60px);
  z-index: 99;
}
.card-1-img img,
.card-2-img img,
.card-3-img img,
.card-4-img img,
img img,
.card-6-img img {
  width: 100%;
  border-radius: 10px;
  height: 150px;
}
.card-1-content,
.card-2-content,
.card-3-content,
.card-4-content,
content,
.card-6-content {
  padding: 10px 20px;
  transform: translateY(-200px);
  opacity: 0;
  text-align: center;
  transition: 0.3s;
}
.card-1:hover > .card-1-content,
.card-2:hover > .card-2-content,
.card-3:hover > .card-3-content,
.card-4:hover > .card-4-content,
hover > content,
.card-6:hover > .card-6-content {
  opacity: 1;
  transform: translateY(-200px);
}
.card-1-content h5,
.card-2-content h5,
.card-3-content h5,
.card-4-content h5,
content h5,
.card-6-content h5 {
  font-weight: bolder;
  font-size: 20px;
  font-family: var(--Title-font);
  color: var(--darkBlue);
}
.card-1-content p,
.card-2-content p,
.card-3-content p,
.card-4-content p,
content p,
.card-6-content p {
  font-weight: lighter;
  font-size: 15pxpx;
  font-family: var(--para-font);
  color: var(--lightblue);
}
/* ############################## What i guarantee SECTION DESIGN #############################  */
.section-6 {
  background-color: white;
}

/* Main Heading */
.sec-6-main-heading {
  text-align: center;
  color: black;
  font-size: 40px;
  font-family: var(--Title-font);
}
.sec-6-main-heading span {
  text-align: center;
  color: red;
  font-size: 40px;
  font-family: var(--Title-font);
  border-bottom: 2px solid black;
}

/* Icons */
.sec-6-icon1 {
  text-align: center;
  color: black;
  font-weight: 500;
  margin: 30px 0px;
}
.sec-6-icon1 h5 {
  font-size: 25px;
  font-weight: bold;
  font-family: var(--para-font);
}
.sec-6-icon {
  color: red;
}
.sec-6-icon2 {
  text-align: center;
  color: black;
  font-weight: 500;
  margin: 30px 0px;
}
.sec-6-icon2 h5 {
  font-size: 25px;
  font-weight: bold;
  font-family: var(--para-font);
}
.sec-6-icon3 {
  text-align: center;
  color: black;
  font-weight: 500;
  margin: 30px 0px;
}
.sec-6-icon3 h5 {
  font-size: 25px;
  font-weight: bold;
  font-family: var(--para-font);
}

/* Peragraphs */
.sec-6-pera1 {
  color: red;
  font-size: 20px;
}
.sec-6-pera2 {
  color: red;
  font-size: 20px;
}
.sec-6-pera3 {
  color: red;
  font-size: 20px;
}
/* ############################## COSTUMIZED STRATIGIES SECTION DESIGN #############################  */
.section-7 {
  position: relative;
  padding: 10px 20px;
  background-image: url("../../images/custumized strategy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  background-color: var(--darkGreen);
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overlay-7 {
  position: relative;
}

.sec-7-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: lightgray; /* Black color with 50% opacity */
  opacity: 0.7;
}

.section-7 .sec-7-main-heading,
.section-7 .sec-7-text,
.section-7 .sec-7-btn {
  position: relative;
  z-index: 1;
}

.section-7 .sec-7-main-heading {
  color: red;
  font-size: 45px;
  font-weight: bolder;
  font-family: var(--Title-font);
}

.section-7 .sec-7-text {
  color: black;
  font-size: 25px;
  font-weight: bold;
  font-family: var(--para-font);
}

.section-7 .sec-7-btn {
  background-color: red;
  font-size: 25px;
  font-weight: lighter;
  font-family: var(--para-font);
  padding: 10px 30px;
  color: white;
  border-radius: 10px;
  border: 1px solid transparent;
  font-weight: bolder;
  transition: 0.5s;
}

.section-7 .sec-7-btn:hover {
  opacity: 0.7;
}
/* ############################## MY POPULARITY SECTION DESIGN #############################  */
.section-8 {
  background-color: white;
  padding: 50px 0px;
}

/* Main Heading */
.sec-8-main-heading {
  text-align: center;
  color: black;
  font-size: 40px;
  font-family: var(--Title-font);
}
.sec-8-main-heading span {
  text-align: center;
  color: red;
  font-size: 40px;
  font-family: var(--Title-font);
  border-bottom: 2px solid black;
}
.sec-8-text {
  font-size: 30px;
  font-weight: bolder;
  font-family: var(--para-font);
  color: black;
}

.sec-8-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 200px;
}
@media (max-width: 786px) {
  .section-8 {
    padding: 50px 0px;
  }
  .sec-8-main-heading {
    font-size: 20px;
    font-weight: bolder;
    font-family: var(--Title-font);
    color: black;
  }
  .sec-8-main-heading span {
    font-size: 20px;
    font-weight: bolder;
    font-family: var(--Title-font);
    color: red;
    border-bottom: 2px solid black;
  }
  .sec-8-text {
    font-size: 25px;
    font-weight: bolder;
    font-family: var(--para-font);
    color: black;
  }
  .sec-8-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
  }
}
@media (max-width: 480px) {
  .section-8 {
    padding: 50px 0px;
  }
  .sec-8-main-heading {
    font-size: 25px;
    font-weight: bolder;
    font-family: var(--Title-font);
    color: black;
  }
  .sec-8-main-heading span {
    font-size: 25px;
    font-weight: bolder;
    font-family: var(--Title-font);
    color: red;
    border-bottom: 2px solid black;
  }
  .sec-8-text {
    font-size: 15px;
    font-family: var(--para-font);
    color: black;
  }
  .sec-8-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
  }
}

/* ############################## SHIPPING SERVICE SECTION DESIGN #############################  */
.section-9 {
  position: relative;
  padding: 10px 20px;
  background-image: url("../../images/sipping banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  background-color: var(--darkGreen);
}

.overlay-9 {
  position: relative;
}

.sec-9-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: lightgray; /* Black color with 50% opacity */
  opacity: 0.7;
}
.sec-9-main-heading {
  text-align: center;
  z-index: 1;
  color: black;
  font-size: 40px;
  font-weight: bold;
  font-family: var(--Title-font);
  padding: 30px 0px 0px 0px;
  position: relative;
}
.sec-9-main-heading span {
  font-weight: bolder;
  font-family: var(--Title-font);
  color: red;
  border-bottom: 2px solid black;
}
.sec-9-left-cont {
  padding: 30px 0px;
  position: relative;
}
.sec-9-left-cont-heading {
  color: black;
  font-size: 40px;
  font-weight: bold;
  font-family: var(--Title-font);
  padding: 20px 10px 20px 10px;
}
.sec-9-left-cont-heading span {
  font-weight: bolder;
  font-family: var(--Title-font);
  color: red;
  border-bottom: 2px solid black;
}
.sec-9-left-cont-para {
  color: black;
  font-size: 20px;
  font-family: var(--para-font);
  padding: 20px 70px 20px 10px;
}
.sec-9-right-cont {
  padding: 50px;
}
.sec-9-right-cont img {
  /* padding: 20px 70px 20px 10px ; */
  transform: scale(0.9);
  width: 100%;
  transition: 0.3s;
}
.sec-9-right-cont img:hover {
  transform: scale(1);
}

.sec-9-commercial-main-heading {
  text-align: center;
  z-index: 1;
  color: black;
  font-size: 40px;
  font-weight: bold;
  font-family: var(--Title-font);
  padding: 30px 0px 0px 0px;
  position: relative;
}
.sec-9-commercial-main-heading span {
  font-weight: bolder;
  font-family: var(--Title-font);
  color: red;
  border-bottom: 2px solid black;
}

.sec-9-commercial-left-cont-para {
  color: black;
  font-size: 20px;
  font-family: var(--para-font);
  display: flex;
  align-items: center;
  height: 100vh;
}
.sec-9-commercial-right-cont-para {
  color: black;
  font-size: 20px;
  font-family: var(--para-font);
  display: flex;
  align-items: center;
  height: 100vh;
}
.sec-9-commercial-img-cont {
  padding: 50px;
}
.sec-9-commercial-img {
  transform: scale(0.9);
  width: 100%;
  transition: 0.3s;
}
.sec-9-commercial-img:hover {
  transform: scale(1);
}
.sec-9-commercial-left-cont {
  position: relative;
}
.sec-9-commercial-right-cont {
  position: relative;
}
@media (max-width: 768px) {
  .sec-9-left-cont-para {
    color: black;
    font-size: 20px;
    font-family: var(--para-font);
    padding: 0px 0px 0px 0px;
    text-align: center;
  }
  .sec-9-right-cont {
    padding: 0px;
  }
  .sec-9-right-cont img {
    transform: scale(0.9);
    width: 100%;
    transition: 0.3s;
  }
  .sec-9-right-cont img:hover {
    transform: scale(1);
  }
  .sec-9-commercial-main-heading {
    text-align: center;
    z-index: 1;
    color: black;
    font-size: 40px;
    font-weight: bold;
    font-family: var(--Title-font);
    padding: 0px 0px 0px 0px;
    position: relative;
  }
  .sec-9-commercial-left-cont-para {
    color: black;
    font-size: 20px;
    font-family: var(--para-font);
    display: flex;
    align-items: center;
    height: 100%;
  }
  .sec-9-commercial-right-cont-para {
    color: black;
    font-size: 20px;
    font-family: var(--para-font);
    display: flex;
    align-items: center;
    height: 100%;
  }
  .sec-9-commercial-img-cont {
    padding: 0px;
  }
  .sec-9-commercial-img {
    transform: scale(0.9);
    width: 100%;
    transition: 0.3s;
  }
  .sec-9-commercial-img:hover {
    transform: scale(1);
  }
  .sec-9-commercial-left-cont {
    position: relative;
  }
  .sec-9-commercial-right-cont {
    position: relative;
  }
}
@media (max-width: 480px) {
  .sec-9-left-cont-para {
    color: black;
    text-align: center;
    font-size: 20px;
    font-family: var(--para-font);
    padding: 0px 0px 0px 0px;
  }
  .sec-9-right-cont {
    padding: 0px;
  }
  .sec-9-right-cont img {
    transform: scale(0.9);
    width: 100%;
    transition: 0.3s;
  }
  .sec-9-right-cont img:hover {
    transform: scale(1);
  }
  .sec-9-commercial-main-heading {
    text-align: center;
    z-index: 1;
    color: black;
    font-size: 40px;
    font-weight: bold;
    font-family: var(--Title-font);
    padding: 0px 0px 0px 0px;
    position: relative;
  }
  .sec-9-commercial-left-cont-para {
    color: black;
    font-size: 20px;
    font-family: var(--para-font);
    display: flex;
    align-items: center;
    height: 100%;
  }
  .sec-9-commercial-right-cont-para {
    color: black;
    font-size: 20px;
    font-family: var(--para-font);
    display: flex;
    align-items: center;
    height: 100%;
  }
  .sec-9-commercial-img-cont {
    padding: 0px;
  }
  .sec-9-commercial-img {
    transform: scale(0.9);
    width: 100%;
    transition: 0.3s;
  }
  .sec-9-commercial-img:hover {
    transform: scale(1);
  }
  .sec-9-commercial-left-cont {
    position: relative;
  }
  .sec-9-commercial-right-cont {
    position: relative;
  }
}

/* ############################## CONTACT SECTION DESIGN #############################  */
.whatsapp-qr{
  background-color: #bda695;
  padding: 40px;
}
.wechat-qr{
  background-color: white;
}